import { Suspense, lazy, useContext, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import { Route, BrowserRouter, Routes, Navigate, Outlet, useLocation } from 'react-router-dom';
import HeaderFooterLayout from './(header-footer-layout)/header-footer-layout';
import ContextComponent from 'src/components/context/context.component';
import AppInit from 'src/components/app-init/app-init.component';
import ROUTES from 'src/static/router.data';
import ModalContainer from 'src/components/modals/modal-container.component';

import APP_MODALS from 'src/static/enums/app.modals';
import ModalSvcContext from 'src/shared/services/modal/modal.context';
import ModalService from 'src/shared/services/modal/modal.service';
import { ModalData } from 'src/shared/models/modals/modals.model';
import ProjectsPage from './(projects-layout)/projects/projects.page';

// import LandingPage from './root.page';

import ProjectLayout from './(projects-layout)/project-layout';
import LandingLayout from './(landing-layout)/landing.layout';
// import FaqPage from './FAQs/faqs-page';

import 'react-toastify/dist/ReactToastify.css';
import 'src/scss/globals.scss';
import GeneralSettingsService from 'src/shared/services/general-settings/general-settings.service';
import geneneralSettingsSvcContext from 'src/shared/services/general-settings/general-settings.context';
import { THEMES } from 'src/static/settings/general-settings.data';
import AuthService from 'src/shared/services/auth/auth.service';
import AuthSvcContext from 'src/shared/services/auth/auth.context';
import UserService from 'src/shared/services/user/user.service';
import UserSvcContext from 'src/shared/services/user/user.context';
import TwitterPage from './(header-footer-layout)/twitter/twitter.page';
import { CountdownProvider } from 'src/components/count-down/count-down-provider';
import DeleteConfirmationModal from 'src/components/modals/delete-quest/deleteQuest.modal';
import TelegramModal from 'src/components/modals/telegramConnect/telegramConnect.modal';
import { TagsProvider } from './(header-footer-layout)/leaderboard/leaderboard.context';
import DeleteTwitterPosts from 'src/components/modals/delete-twitter-posts/deleteTwitterPosts.modal.tsx';
import DisqualifyUserModal from 'src/components/modals/disqualify-user/disqualify-user.modal.tsx';


// prettier-ignore
const ProjectDetailsPage = lazy(() => import('./(projects-layout)/projects/details/project-details[id].page'));
// prettier-ignore
const EditProjectPage = lazy(() => import('./(header-footer-layout)/projects/edit/project-edit[idx].page'));
const EditProjectsAdmin = lazy(
	() => import('./(header-footer-layout)/projects/edit/project-edit[idx].admin.page')
);

// prettier-ignore

const ProfilePage = lazy(() => import('./(header-footer-layout)/pwr-community-profile/pwr-community-profile-page.tsx'));
const EditProfilePage = lazy(() => import('./(header-layout)/editProfile/editProfile.page'));
const PwrCommunityAmbassador = lazy(() => import('./(header-footer-layout)/pwr-community/pwr-community.page.tsx'));

const TestPage = lazy(() => import('./(header-footer-layout)/test/test.page'));
const UiKitPage = lazy(() => import('./dev/ui-kit/ui-kit'));
const CreateQuestPage = lazy(
	() => import('src/app/pages/create-quest-page/create-quest.component')
);
const DeleteQuestPage = lazy(() => import('src/app/pages/delete-quest-page/delete-quest.page'));
const OtherProfile = lazy(() => import('src/app/(header-footer-layout)/pwr-community-profile/other-profile.component'))
const FaqPage = lazy(() => import('./(header-footer-layout)/faq/faq.page'));
const AuthCallbackPage = lazy(() => import('./(header-footer-layout)/twitter/twitter.page'));
const DashboardPage = lazy(() => import('./(admin-layout)/dashboard/dashboard.page'));
const AdminPage = lazy(() => import('src/app/admin-page/admin-page'));
const AirdropPage = lazy(() => import('./(header-footer-layout)/airdrop/airdrop.page'));
const AdminLeaderBoardComponent = lazy(() => import('src/components/adminLeaderboard/adminLeaderboard.component.tsx'));
const NotFoundPage = lazy(() => import('./(header-footer-layout)/404/404.page'));
const LeadeBoardPage = lazy(() => import('./(header-footer-layout)/leaderboard/leaderboard.page'))
const HowItWorksPage = lazy(
	() => import('./(header-footer-layout)/how-it-works/how-it-works.page')
);
// modals
const LoginModal = lazy(() => import('src/components/modals/login/login.modal'));
const ContactModal = lazy(() => import('src/components/modals/contact/contact.modal'));
const ProjectImageModal = lazy(
	() => import('src/components/modals/project-image/project-image.modal')
);
const DeleteCommentModal = lazy(
	() => import('src/components/modals/delete-comment/delete-comment.modal')
);

const SessionExpiredModal = lazy(() => import('src/components/modals/login/session-expired.modal'));

const DeleteProjectModal = lazy(
	() => import('src/components/modals/delete-project/delete-project.modal')
);
const SelectYourCountryFlagPageModal = lazy(() => import('./(header-footer-layout)/leaderboard/personalize-your-page-modals/select-your-country-flag.modal.tsx'))
const TagsPageModal = lazy(() => import('./(header-footer-layout)/leaderboard/personalize-your-page-modals/tags-page.modal.tsx'))

const InsertLinkModal = lazy(() => import('src/components/modals/insertLink/insertLink.modal.tsx'));

function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
}

function RoutingComponent() {
	function AuthRequiredLayout() {
		const authSvc = useContext<AuthService>(AuthSvcContext);

		// We are checking if the user is authenticated
		const isAuthenticated = authSvc.isLoggedIn();

		// here we are checking if the user is an admin or not, i don`t know how to identify an admin but this will give you some insights about the logic i wanted to implement
		// const isAdmin = true;

		// here we are redirecting unauthenticated users to the login page so that they can login
		if (!isAuthenticated) {
			return <Navigate to={ROUTES.root} />;
		}

		// We will then allow the admins to access the dashboard
		return <Outlet />;
	}

	function AdminRequiredLayout() {
		const userSvc = useContext<UserService>(UserSvcContext);

		if (!userSvc.isAdmin()) {
			return <Navigate to={ROUTES.root} />;
		}

		return <Outlet />;
	}

	return (
		<>
			<Routes>
				{/* layout for landing page | TODO: move to header footer layout adding negative padding */}
				<Route element={<LandingLayout />}>
					{/* landing page */}
					{/* <Route path={ROUTES.root} element={<LandingPage />} /> */}
				</Route>

				{/* header footer layout */}
				<Route element={<HeaderFooterLayout />}>

					{/* dev only routes */}
					<Route path={ROUTES.airdrop} element={<AirdropPage />} />
					<Route path={ROUTES.dashboard.howItWorks} element={<HowItWorksPage />} />
					<Route path={ROUTES.root} element={<AirdropPage />} />
					<Route path="/test" element={<TestPage />} />

					{import.meta.env.DEV && (
						<>
							<Route path={'/ui'} element={<UiKitPage />} />
						</>
					)}
					{/* login */}
					{/* <Route path={ROUTES.loginModal} element={<LoginModalPage />} /> */}
					{/* profile */}
					<Route path={ROUTES.profile.root} element={<ProfilePage />} />
					<Route path={ROUTES.profile.userProfile} element={<OtherProfile />} />

					<Route path={ROUTES.PwrCommunity} element={<PwrCommunityAmbassador />} />

					<Route path={ROUTES.dashboard.quests.leaderboardTableComponent} element={<AdminLeaderBoardComponent />} />
					<Route path={ROUTES.dashboard.userLeaderboard} element={<LeadeBoardPage />} />

					{/* faq */}
					<Route path={ROUTES.faq} element={<FaqPage />} />
					project
					<Route path={ROUTES.projects.details} element={<ProjectDetailsPage />} />
					<Route path={ROUTES.dashboard.adminPage} element={<AdminPage />} />
					<Route path={ROUTES.dashboard.quests.create} element={<CreateQuestPage />} />
					<Route path={ROUTES.dashboard.quests.delete} element={<DeleteQuestPage />} />
					<Route path={ROUTES.auth.twitter} element={<TwitterPage />} />
					{/* 404 */}
					<Route path="*" element={<NotFoundPage />} />
					<Route path="/auth-callback" Component={AuthCallbackPage} />
					{/* *~~~ proteted routes ~~~* */}
					<Route element={<AuthRequiredLayout />}>
						<Route path={ROUTES.profile.edit} element={<EditProfilePage />} />

						<Route path={ROUTES.projects.edit} element={<EditProjectPage />} />
						<Route element={<AdminRequiredLayout />}>
							{/* Admin */}
							<Route path={ROUTES.dashboard.root} element={<DashboardPage />} />
							<Route
								path={ROUTES.admin.projects.edit}
								element={<EditProjectsAdmin />}
							/>
						</Route>
					</Route>
				</Route>

				<Route element={<ProjectLayout />}>
					{/* projects */}
					<Route path={ROUTES.projects.root} element={<ProjectsPage />} />
				</Route>
			</Routes>
		</>
	);
}

function AppModals() {
	const modals: {
		[key: string]: any;
	} = {
		// [APP_MODALS.EXAMPLE_MODAL]: ExampleModal,
		[APP_MODALS.CONTACT_MODAL]: ContactModal,
		[APP_MODALS.LOGIN_MODAL]: LoginModal,
		[APP_MODALS.DELETE_QUEST]: DeleteConfirmationModal, // Add this
		[APP_MODALS.TELEGRAM_MODAL]: TelegramModal, // Add this
		[APP_MODALS.DELETE_POST_MODAL]: DeleteTwitterPosts,
		[APP_MODALS.IMAGE_MODAL]: ProjectImageModal,
		[APP_MODALS.DELETE_COMMENT]: DeleteCommentModal,
		[APP_MODALS.SESSION_EXPIRED]: SessionExpiredModal,
		[APP_MODALS.DELETE_PROJECT]: DeleteProjectModal,

		//mobile leaderboard
		[APP_MODALS.SELECT_YOUR_COUNTRY_FLAG_MODAL]: SelectYourCountryFlagPageModal, // Make sure to import this
		[APP_MODALS.TAGS_PAGE_MODAL]: TagsPageModal,
		[APP_MODALS.INSERT_LINK_MODAL]: InsertLinkModal,
		[APP_MODALS.DISQUALIFY_USER_MODAL]: DisqualifyUserModal,

		//YOUTUBE MODAL
		[APP_MODALS.YOUTUBE_MODAL]: TelegramModal, // Add this


	};

	const modalSvc = useContext<ModalService>(ModalSvcContext);

	return (
		<ModalContainer>
			<Suspense fallback={<div></div>}>
				{modalSvc.getOpenModals().map((modal: ModalData, idx: number) => {
					const ModalComp = modals[modal.id];
					return <ModalComp key={idx} {...modal.data} />;
				})}
			</Suspense>
		</ModalContainer>
	);
}

export default function RootLayout() {
	const settingsSvc = useContext<GeneralSettingsService>(geneneralSettingsSvcContext);

	const endTime =
		Date.now() + 12 * 24 * 60 * 60 * 1000 + 8 * 60 * 60 * 1000 + 15 * 60 * 1000 + 26 * 1000;
	// const [referralCode, setReferralCode] = useState('');
	const modalService = new ModalService();
	return (
		<ContextComponent>
			<AppInit>
				<CountdownProvider endTime={endTime}>
					<TagsProvider>
						<BrowserRouter>
							<ModalSvcContext.Provider value={modalService}>

								<Suspense fallback={<div></div>}>
									<RoutingComponent />
								</Suspense>

								<ScrollToTop />

								<ToastContainer
									theme={settingsSvc.getTheme() === THEMES.DARK ? 'dark' : 'light'}
								/>
								<AppModals />

							</ModalSvcContext.Provider>
						</BrowserRouter>
					</TagsProvider>
				</CountdownProvider>
			</AppInit>
		</ContextComponent>
	);
}
