

const ROUTES = {
	// landing
	root: '/',

	// dashboard
	dashboard: {
		root: '/dashboard',
		adminPage: '/admin',
		howItWorks: '/how-it-works',
		userLeaderboard: '/leaderboard',
		quests: {
			create: '/admin/createQuest',
			delete: '/admin/deleteQuest',
			leaderboardTableComponent: '/admin/leaderboard',
		}
	},
	// images: {
	// 	pfp: 'https://digitalidentity.pwrlabs.io/userInfo/image?address=:address',
	// },
	governance: 'https://governance.pwrlabs.io/',
	staking: 'https://staking.pwrlabs.io/',
	// projects
	projects: {
		root: 'https://community.pwrlabs.io/projects',
		details: '/projects/:id',
		edit: '/projects/:id/edit',
	},
	PwrCommunity: '/pwr-community',
	//Airdrop
	airdrop: '/airdrop',
	telegram: '/telegramConnect',
	//
	faq: '/faq',

	//profile
	profile: {
		root: '/profile/:id',
		edit: '/profile/edit',
		userProfile: '/user/profile/:username'
		// upvotes: '/profile/upvotes',
		// subsmissions: '/profile/submissions',
	},
	test: '/test',
	// apply for grant
	grantApply: '/grant/apply',

	// login modal

	external: {
		wallet: '',
		telegram: 'https://t.me/:user',
	},

	auth: {
		twitter: '/auth/twitter',
	},

	admin: {
		projects: {
			details: '/admin/projects/:id',
			edit: '/admin/projects/:id/edit',
		},

	},
};

export default ROUTES;
