enum APP_MODALS {
	EXAMPLE_MODAL = '3X4MPL3_M0D4L',
	CONTACT_MODAL = 'CONTACT_MODAL',
	LOGIN_MODAL = 'LOGIN_MODAL',
	IMAGE_MODAL = 'IMAGE_MODAL',
	DELETE_MODAL = 'DELETE_MODAL',
	DELETE_PROJECT = 'DELETE_PROJECT',
	DELETE_COMMENT = 'DELETE_COMMENT',
	DELETE_QUEST = "DELETE_QUEST",
	SESSION_EXPIRED = 'SESSION_EXPIRED',
	TELEGRAM_MODAL = 'TELEGRAM_MODAL',
	SELECT_YOUR_COUNTRY_FLAG_MODAL = 'SELECT_YOUR_COUNTRY_FLAG_MODAL',
	TAGS_PAGE_MODAL = 'TAGS_PAGE_MODAL',
	ADMIN_DELETE_QUESTS_COMPONENT_MODAL = 'ADMIN_DELETE_QUESTS_COMPONENT_MODAL',
	INSERT_LINK_MODAL = 'INSERT_LINK_MODAL',
	YOUTUBE_MODAL = 'YOUTUBE_MODAL',
	DELETE_POST_MODAL = 'DELETE_POST_MODAL',
	DISQUALIFY_USER_MODAL = 'DISQUALIFY_USER_MODAL'
}

export default APP_MODALS;
