import { Link, useLocation } from 'react-router-dom';
import { useContext, useState, useEffect } from 'react';


import ROUTES from 'src/static/router.data';
import GeneralSettingsService from 'src/shared/services/general-settings/general-settings.service';
import geneneralSettingsSvcContext from 'src/shared/services/general-settings/general-settings.context';

import './header.scss';
import PwrImagotipo from 'src/components/logos/pwr-imagotipo/pwr-imagotipo.logo';
import AuthService from 'src/shared/services/auth/auth.service';
import AuthSvcContext from 'src/shared/services/auth/auth.context';
import ModalService from 'src/shared/services/modal/modal.service';
import ModalSvcContext from 'src/shared/services/modal/modal.context';
import APP_MODALS from 'src/static/enums/app.modals';
import UserService from 'src/shared/services/user/user.service';
import UserSvcContext from 'src/shared/services/user/user.context';

import LoginModal from 'src/components/modals/login/login.modal';
import { setupTwitterLoginListener } from 'src/shared/utils/broadcastChannel';
import { toast } from 'react-toastify';

const navBttns = [
	{ label: 'Projects', href: ROUTES.projects.root },
	{ label: 'Airdrops', href: ROUTES.root },
	{ label: 'Governance', href: ROUTES.governance },
	{ label: 'Staking', href: ROUTES.staking },

	{ label: 'FAQs', href: ROUTES.faq },
];

export default function HeaderComponent() {
	// *~~~ inject dependencies ~~~* //

	const authSvc = useContext<AuthService>(AuthSvcContext);
	const userSvc = useContext<UserService>(UserSvcContext);
	const modalSvc = useContext<ModalService>(ModalSvcContext);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const { pathname } = useLocation();

	const [mobileNavOpen, setMobileNavOpen] = useState(false);
	const [showHeader, setShowHeader] = useState(true);
	const [lastScrollY, setLastScrollY] = useState(0);

	const toggleMobileNav = () => {
		setMobileNavOpen(!mobileNavOpen);
	};

	// *~~~ Theme ~~~* //
	const settingsSvc = useContext<GeneralSettingsService>(geneneralSettingsSvcContext);

	function toggleTheme() {
		settingsSvc.toggleTheme();
	}

	function openLoginModal() {
		modalSvc.open(APP_MODALS.LOGIN_MODAL);
	}

	function logout() {
		authSvc.logout();
		localStorage.removeItem('bearerToken');

		toast.success('Logged out successfully');
	}
	useEffect(() => {
		const cleanupTwitterLoginListener = setupTwitterLoginListener(authSvc, setIsModalOpen);
		return () => cleanupTwitterLoginListener();
	}, [authSvc]);


	function connectToTwitter() {
		const bc = new BroadcastChannel('twitter-login');
		bc.onmessage = (event) => {
			if (event.data.burnerToken) {
				localStorage.setItem('bearerToken', event.data.burnerToken);
				console.log('Token stored successfully');
				// Now you can trigger any function that requires the token
			}
		};

		// Check for referral code in localStorage
		const referralCode = localStorage.getItem('referral_code');
		const twitterSignInUrl = referralCode
			? `https://pwrchallenges.pwrlabs.io/api/login/twitter?callbackUrl=https://airdrop.pwrlabs.io/auth/twitter&referral_code=${referralCode}`
			: 'https://pwrchallenges.pwrlabs.io/api/login/twitter?callbackUrl=https://airdrop.pwrlabs.io/auth/twitter';

		const w = window.open(
			twitterSignInUrl,
			'connect',
			'height=600,width=800'
		);

		if (w === null) {
			console.log('Failed to open the authentication window.');
			return;
		}

		const checkWindowClosed = setInterval(() => {
			if (w.closed) {
				clearInterval(checkWindowClosed);
				console.log('the window was closed');
				// Perform any additional actions here
			}
		}, 500);
	}

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const referralCode = searchParams.get('referral_code');
		if (referralCode) {
			localStorage.setItem('referral_code', referralCode);
		}
	}, []);

	// *~~~ Scroll Effect ~~~* //
	useEffect(() => {
		const handleScroll = () => {
			if (window.innerWidth < 768) {
				// Ensure the scroll effect only works on mobile
				if (window.scrollY <= 0) {
					// If the user is at the top of the page, always show the header
					setShowHeader(true);
				} else if (window.scrollY > lastScrollY) {
					// Scrolling down
					setShowHeader(false);
				} else {
					// Scrolling up
					setShowHeader(true);
				}
				setLastScrollY(window.scrollY);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [lastScrollY]);

	// this is for logging out users
	// const mutation = useMutation(() => QueryApi.auth.twitterLogOut());

	// const handleTwitterLogOut = () => {
	// 	mutation.mutate();
	// };

	return (
		<div>
			<nav
				className={`fixed bg-opacity-5 top-0 left-0 w-full z-[100] transparent px-4 md:px-[24px] lg:px-[100px] py-5 transition-transform duration-300 ${showHeader ? 'translate-y-0 md' : '-translate-y-full'
					}`}
			>

				<div className="bg-white dark:bg-dark-800 shadow-2xl rounded-[30px] px-[50px] h-header">
					{/* desktop */}
					<div className="flex justify-between items-center  w-full h-full ">
						{/* brand */}
						<Link to={ROUTES.root} className="brand">
							<PwrImagotipo />
						</Link>

						{/* navbar links */}
						<div className="hidden md:flex items-center gap-x-6 ">
							{/* link con */}
							<ul className="flex items-center gap-x-6">
								{userSvc.isAdmin() && (
									<li>
										<Link
											to={ROUTES.dashboard.root}
											className={`navbar-link ${pathname.includes('dashboard') ? 'active' : ''
												}`}
										>
											Dashboard
										</Link>
									</li>
								)}
								{navBttns.map((nav, idx) => (
									<li key={idx}>
										<Link
											to={nav.href}
											className={`navbar-link ${pathname.includes(nav.href) ? 'active' : ''
												}`}
										>
											{nav.label}
										</Link>
									</li>
								))}
							</ul>
						</div>

						{/* user */}
						<div className="hidden md:flex items-center gap-x-6 ">

							{authSvc.isLoggedIn() ? (
								<>
									{/* <Link
										to={ROUTES.profile.root.replace(
											':id',
											userSvc.getUserData().id
										)}
									>
										<img
											src={userSvc.getUserData().pfp}
											alt=""
											className="w-8 h-8 rounded-full hover:scale-110 hover:shadow-lg transition duration-300 ease-in-out"
										/>
									</Link> */}
									<button
										className="relative hover:text-white overflow-hidden w-[102px] h-9 px-4 rounded-[32px] border border-gray-800 dark:border-purple-50 dark:text-white text-sm font-normal font-['Space Grotesk'] leading-normal group"
										onClick={logout}
									>
										<span className="absolute inset-0 bg-ablue-200 transform -translate-x-full transition-transform duration-300 ease-in-out group-hover:translate-x-0"></span>
										<span className="relative z-10">log out</span>
									</button>
								</>
							) : (<>

								<button
									className="text-white text-sm hover:bg-ablue-400 transition duration-200 hover:shadow-lg font-normal flex justify-center items-center font-['Space Grotesk'] leading-normal w-[167px] px-4 py-2 bg-blue-700 rounded-[32px] "
									onClick={connectToTwitter}
								>
									<i className="fa-brands fa-x-twitter text-white mr-2"></i> <span >Connect</span>

								</button>
							</>
							)}

							<button
								className="theme_btn text-agrey-500 dark:text-white"
								onClick={toggleTheme}
							>
								<div className="dark:text-white dark:hover:text-ablue-200 hover:text-ablue-300 transition duration-200 ease-in hover:scale-110 hover:shadow-sm">
									<i
										className={`fa-if  fas fa-${settingsSvc.getTheme() === 'light' ? 'moon ' : 'sun-bright'
											}`}
									></i>
								</div>
							</button>


						</div>

						<div className="burger-button md:hidden flex">


							{/* This is a simple burger icon. You can replace this with any SVG or icon library you prefer. */}
							<button
								data-collapse-toggle="navbar-sticky"
								type="button"
								className={`burger ${mobileNavOpen ? 'active' : ''}`}
								aria-controls="navbar-sticky"
								aria-expanded="true"
								onClick={toggleMobileNav}
							>
								<div className="h-line h-line1 dark:bg-white"></div>
								<div className="h-line h-line2 dark:bg-white"></div>
								<div className="h-line h-line3 dark:bg-white"></div>
							</button>
						</div>
					</div>

					{/* mobile */}
					<div className="flex dark:bg-dark-800 justify-center">
						{/* Mobile burger icon */}

						{/* Mobile navigation menu */}
						{mobileNavOpen && (
							<div className="transition-opacity duration-300 ease-in opacity-100 shadow-bottom absolute top-13 mt-[-23px]    w-[92%] rounded-bl-2xl rounded-br-2xl shadow-bl-2xl dark:bg-dark-800 bg-white  p-4 flex flex-col items-center space-y-6">
								{/* links */}
								<div className="space-y-9 mt-5 ">
									<div className="">
										<Link
											to={ROUTES.projects.root}
											className=" justify-center font-medium text-agrey-900 dark:text-white flex items-center gap-x-2 "
										>
											Projects
										</Link>
									</div>

									{/* airdrops */}

									<div className="">
										<Link
											to={ROUTES.root}
											className=" justify-center font-medium text-agrey-900 dark:text-white flex items-center gap-x-2 "
										>
											Airdrops
										</Link>
									</div>
									{/* Governance */}

									<div className="">
										<Link
											to={ROUTES.governance}
											className=" justify-center font-medium text-agrey-900 dark:text-white flex items-center gap-x-2 "
										>
											Governance
										</Link>
									</div>

									{/* staking */}

									<div className="">
										<Link
											to={ROUTES.staking}
											className=" justify-center font-medium text-agrey-900 dark:text-white flex items-center gap-x-2 "
										>
											Staking
										</Link>
									</div>

									{/* faq */}

									<div className="">
										<Link
											to={ROUTES.faq}
											className=" justify-center font-medium text-agrey-900 dark:text-white flex items-center gap-x-2 "
										>
											FAQs
										</Link>
									</div>
									{authSvc.isLoggedIn() ? (
										<>
											{/* <Link
										to={ROUTES.profile.root.replace(
											':id',
											userSvc.getUserData().id
										)}
									>
										<img
											src={userSvc.getUserData().pfp}
											alt=""
											className="w-8 h-8 rounded-full hover:scale-110 hover:shadow-lg transition duration-300 ease-in-out"
										/>
									</Link> */}
											<button
												className="w-[102px] h-9 px-4  rounded-[32px] border border-gray-800 dark:border-purple-50 dark:text-white text-sm font-normal font-['Space Grotesk'] leading-normal"
												onClick={logout}
											>
												log out
											</button>
										</>
									) : (<>

										<button
											className="text-white text-sm hover:bg-ablue-400 transition duration-200 hover:shadow-lg font-normal flex justify-center items-center font-['Space Grotesk'] leading-normal w-[167px] px-4 py-2 bg-blue-700 rounded-[32px] "
											onClick={() => setIsModalOpen(true)}
										>
											<i className="fa-brands fa-x-twitter text-white mr-2"></i> <span >Connect</span>

										</button>
									</>
									)}
								</div>

								{/* buttons */}
								<div className="hidden md:flex items-center text-white gap-x-6 ">
									{authSvc.isLoggedIn() ? (
										<>
											<Link
												to={ROUTES.profile.root.replace(':id', userSvc.getUserData().id)}
											>
												<img
													src={userSvc.getUserData().pfp}
													alt=""
													className="w-8 h-8 rounded-full hover:scale-110 hover:shadow-lg transition duration-300 ease-in-out"
												/>
											</Link>
											<button
												className="w-[102px] h-9 px-4  rounded-[32px] border border-gray-800 dark:border-purple-50 dark:text-white text-sm font-normal font-['Space Grotesk'] leading-normal"
												onClick={logout}
											>
												log out
											</button>
										</>
									) : (
										<button
											className="text-white text-sm  font-normal flex justify-center items-center font-['Space Grotesk'] leading-normal w-[167px] px-4 py-2 bg-blue-700 rounded-[32px] "
											onClick={openLoginModal}
										>
											<i className="fa-brands fa-x-twitter text-white"></i> Connect

										</button>

									)}
								</div>
							</div>
						)}
					</div>
				</div>

			</nav>
			<LoginModal
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				onConnect={connectToTwitter}
			/>
		</div>
	);
}