const _baseUrl = import.meta.env.VITE_APP_AIRDROPHOST;
const _imageServerUrl = import.meta.env.VITE_APP_IMAGE_SERVER_URL;
const _host = import.meta.env.VITE_APP_HOST;
const _diApi = import.meta.env.VITE_APP_DIGITAL_IDENTITY_API;

const api = {
	baseUrl: _baseUrl,
	host: _host,
	diUrl: _diApi,

	images: {
		getPresignedUrl: `${_imageServerUrl}/images/one?filename=:filename`,
		getMultiplePresignedUrls: `${_imageServerUrl}/images?filenames=:filenames`,
	},

	auth: {
		googleSignIn: `${_baseUrl}/google-sign-in`,
		twitterLink: `${_baseUrl}/twitter-sign-in?callbackUrl=:callbackUrl`,

		twitterSignIn: `${_baseUrl}/api/login/twitter`,
		twitterSigninPost: `${_baseUrl}/api/login/twitter/burnToken`,
		twitterLogOut: `${_baseUrl}/api/logout/twitter`,

		logout: `${_baseUrl}/google-user/logout`,
		session: `${_baseUrl}/api/session`,
	},

	user: {
		getInfo: `${_diApi}/userInfo/?address=:address`,

		googleGetInfo: `${_baseUrl}/user/:id`,
		getUpvotes: `${_baseUrl}/projects/:userId/upvotes?:params`,
		getUserById: (userId: string) => `${_baseUrl}/api/users/${userId}`, // Use a function to construct the URL

		// Flag operations
		addOrUpdateFlag: (flag: string) => `${_baseUrl}/api/user/flag?flag=${flag}`,
		deleteFlag: () => `${_baseUrl}/api/user/flag?flag=`,

		// Interests operations
		addOrUpdateInterests: `${_baseUrl}/api/user/interests`,

		// Leaderboard
		getTop11: `${_baseUrl}/api/user/leaderboard/top11`,
		//get activity
		getActivity: `${_baseUrl}/api/user/activity`,
		getLeaderboardTopUsers: (page: number, limit: number) =>
			`${_baseUrl}/api/user/leaderboard/topusers?page=${page}&limit=${limit}`,
		getLeaderboardMe: `${_baseUrl}/api/user/leaderboard/me`,
		//get user profile
		getUserProfile: (username: string) => `${_baseUrl}/api/user/${username}`,
	},

	userInvitations: {
		getUserInvitations: (userId: string) => `${_baseUrl}/api/user-invitations/${userId}`,
	},
	profile: {
		googleGetInfo: `${_baseUrl}/google-user/:id`,

		upvotedProjects: `${_baseUrl}/user-upvotes/:userId`,
		submittedProjects: `${_baseUrl}/user-created-project/:userId`,

		editProfile: `${_baseUrl}/google-user`,
	},

	projects: {
		getAll: `${_baseUrl}/projects?:params`, // replace F with filters
		getOne: `${_baseUrl}/projects/:id`, // replace :id with project id

		search: `${_baseUrl}/project-name/:name`,

		addUpvote: `${_baseUrl}/upvote`, // replace :id with project id
		removeUpvote: `${_baseUrl}/upvote`, // replace :id with project id

		add: `${_baseUrl}/requestGrant`, // Post a new project
		editProject: `${_baseUrl}/requestGrant`,

		delete: `${_baseUrl}/requestGrant`,
	},
	challenges: {
		postQuest: `${_baseUrl}/api/admin/quests/createQuest`,
		getQuests: `${_baseUrl}/api/quests`,
		getChallengeById: (questId: string) => `${_baseUrl}/api/quests/${questId}`,
		deleteQuest: `${_baseUrl}/api/quests/delete`,
		verifyChallenge: `${_baseUrl}/api/quests/verify`,
		verifyChallengeWithDiscord: (userId: string, challengeId: string, discordId: string) => `${_baseUrl}/api/quests/verify?userId=${userId}&challengeId=${challengeId}&discordId=${discordId}`,
		getDiscordAuthUrl: (userId: string) => `${_baseUrl}/api/login/discord?callbackUrl=https://airdropdev.pwrlabs.io/auth/discord&callerId=${userId}`, // New endpoint
		requestReferralPoints: `${_baseUrl}/api/quests/referralCode?referredUserId=:referredUserId&referralCode=:referralCode`,

		fetchCompletedChallenges: `${_baseUrl}/api/quests/completed`,
		finalizeChallenge: (questId: string) => `${_baseUrl}/api/quests/${questId}/finalize`,
		forceVerify: (userId: string, challengeId: string) =>
			`${_baseUrl}/forceVerify?userId=${userId}&challengeId=${challengeId}`,
		fetchInreviewChallenges: `${_baseUrl}/api/quests/inreview`,

		//Claim Daily Bonus POINTS
		claimBonusPoint: `${_baseUrl}/api/claimBonusPoint`, // New endpoint for claiming bonus points
		getNextClaimTime: `${_baseUrl}/api/getNextClaimTime`, // New endpoint for claiming bonus points
		//Telegram Challenges
		connectTelegram: `${_baseUrl}/api/login/telegram`,
		//Email Challenge
		subscribeEmail: (email: string, challengeId: string) => `${_baseUrl}/api/beehiive/subscribeEmail?email=${email}&challengeId=${challengeId}`,


		//Quest Buttons Status
		setButtonStarted: (challengeId: string, action: string) => `${_baseUrl}/api/user/challenge/action?action=${action}&challengeId=${challengeId}`,
		getButtonStatus: `${_baseUrl}/api/user/challenge/actions`
	},
	twitterPosts: {
		getUserPosts: (page: number, itemsPerPage: number) => `${_baseUrl}/api/user/embedcode?page=${page}&itemsPerPage=${itemsPerPage}`,
	},
	//pwr-community AKA: Ambassador - In PWR Airdrop Project
	ambassador: {
		submitEmbedCode: `${_baseUrl}/api/user/embedcode`,
		getAllEmbedCodes: (page: number, itemsPerPage: number) => `${_baseUrl}/api/user/embedcode/all?page=${page}&itemsPerPage=${itemsPerPage}`,

	},
	leaderboard: {
		getSummary: `${_baseUrl}/api/admin/leaderboard/summary`,
		// getTop10: `${_baseUrl}/api/admin/leaderboard/top10`, // OLD API
		getTop10: `${_baseUrl}/api/admin/leaderboard/top10`,  // Updated to v2
		getTop10V2: `${_baseUrl}/api/admin/leaderboard/topusers/v2`,
		getSummaryV2: `${_baseUrl}/api/admin/leaderboard/summary/v2`, // New v2 API

	},
	///project/3/comments
	comments: {
		get: (projectId: string | number) => `${_baseUrl}/project/${projectId}/comments`, // Fetch comments for a project
		add: `${_baseUrl}/comment`, // Post a new comment
		deleteComment: `${_baseUrl}/comment`,
	},
	admin: {
		// getTotalSubmissions: `${_baseUrl}/${userId}/total-submissions`,

		getTotalUsers: `${_baseUrl}/total-users`,
		getTotalGrants: `${_baseUrl}/total-grants`,
		getProjectStatus: `${_baseUrl}/project-status`,

		getUsersData: `${_baseUrl}/stats/users-data`,

		getChartData: `${_baseUrl}/chart-data`,

		getGrantChartData: `${_baseUrl}/admin/grant-chart-data/:time`,
		getUnresolvedQuests: `${_baseUrl}/api/admin/quests/unresolved`,
		requestQuestVerification: `${_baseUrl}/api/quests/request/verify`,
		deleteUserPost: `${_baseUrl}/api/admin/user/embedcode/delete`,
		searchUsers: (username: string, limit: number = 20) => `${_baseUrl}/api/search/users/${username}/${limit}`,
		updateUserPoints: `${_baseUrl}/api/admin/user/points/update`,
		disqualifyUser: (userId: string) => `${_baseUrl}/api/admin/user/disqualify?userId=${userId}`,

	},

};

export default api;
