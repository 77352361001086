import React, { useContext, useEffect, useRef } from 'react';
import { useMutation } from 'react-query';
import ModalSvcContext from 'src/shared/services/modal/modal.context';
import APP_MODALS from 'src/static/enums/app.modals';
import QueryApi from 'src/shared/api/query-api';
import { toast } from 'react-toastify';

// WebSocket connection for admin (you may want to open the WebSocket connection globally)
// const ws = new WebSocket('ws://51.83.251.45:8081');

type DisqualifyConfirmationModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    userId: string;
};

const DisqualifyUserModal = ({ isOpen, onClose, onConfirm, userId }: DisqualifyConfirmationModalProps) => {
    const modalSvc = useContext(ModalSvcContext);
    const modalRef = useRef<HTMLDivElement>(null);

    // React Query mutation to disqualify the user
    const disqualifyMutation = useMutation(() => QueryApi.admin.disqualifyUser(userId), {
        onSuccess: () => {
            // Notify admin via toast
            toast.success('User disqualified successfully');
            modalSvc.closeModal(APP_MODALS.DISQUALIFY_USER_MODAL); // Close the modal

            // Send WebSocket message to notify the disqualified user
            // const message = JSON.stringify({
            //     event: 'disqualified',
            //     userId, // The user who got disqualified
            //     message: `You are disqualified. ${new Date().toUTCString()}`, // Custom message
            // });

            // Send the message to the WebSocket server
            // ws.send(message);

            // Automatically close modal and refresh page after 2 seconds
            setTimeout(() => {
                onConfirm(); // Trigger any additional logic
                window.location.reload(); // Refresh the page
            }, 2000);
        },
        onError: (error) => {
            toast.error('Failed to disqualify user');
            console.error('Failed to disqualify user:', error);
        },
    });

    const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation(); // Prevent modal from closing prematurely

        disqualifyMutation.mutate(); // Trigger the disqualify mutation
    };

    const handleClose = (e: React.MouseEvent<HTMLButtonElement> | MouseEvent) => {
        e.preventDefault();
        e.stopPropagation(); // Prevent modal from closing prematurely

        onClose(); // Close the modal
    };

    const handleOutsideClick = (event: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            onClose();
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen]);

    // Cleanup WebSocket when component unmounts
    // useEffect(() => {
    //     return () => {
    //         ws.close();
    //     };
    // }, []);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-[8000] bg-gray-800 bg-opacity-50">
            <div ref={modalRef} className="bg-white rounded-2xl p-6 w-full max-w-md">
                <div className="flex justify-between items-center pb-3">
                    <h2 className="text-xl font-semibold">Disqualify User</h2>
                    <button onClick={handleClose} className="text-gray-400 hover:text-gray-600">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <p className="mt-4 text-gray-600">Are you sure you want to disqualify this user? Once disqualified, their points will be reset to 0.</p>
                <div className="mt-6 flex justify-end space-x-4">
                    <button onClick={handleClose} className="bg-white border border-gray-300 text-gray-700 rounded-full w-full px-4 py-2 hover:bg-gray-50">
                        Cancel
                    </button>
                    <button onClick={handleConfirm} className="bg-red-500 text-white rounded-full px-4 py-2 w-full hover:bg-red-600">
                        Disqualify
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DisqualifyUserModal;
